<template>
  <MenuDropdown v-if="serviceMenu" :menu="menu" size="small" />
</template>

<script setup lang="ts">
import type { SiteSettings } from "~/@types/siteSettings"

const { data: settings } = useNuxtData<SiteSettings>("site-settings")

const serviceMenu = settings.value?.serviceMenu

const menu = {
  label: "Services",
  dropdowns: serviceMenu,
}
</script>
