<template>
  <div class="container">
    <header class="flex items-center justify-center py-3">
      <div class="flex flex-1 items-center">
        <button class="mr-auto inline-flex sm:hidden" @click="toggleMenu(true)">
          <Icon name="ci:hamburger" class="text-3xl hover:opacity-60" />
        </button>

        <div class="hidden sm:block">
          <MenuServices />
        </div>
      </div>
      <div class="w-40">
        <NuxtLink to="/" title="CALIBRE">
          <img class="w-full" src="~/assets/logo.svg" alt="CALIBRE" />
        </NuxtLink>
      </div>
      <div class="flex flex-1 items-center">
        <SiteSocialList class="ml-auto hidden items-center gap-5 sm:flex" />
      </div>
    </header>

    <NavMain
      class="mx-auto flex max-w-2xl items-center justify-between px-4 max-sm:hidden"
    />

    <SiteSideMenu :opened="menuOpened" :toggle-menu="toggleMenu" />
  </div>
</template>

<script setup lang="ts">
const [menuOpened, toggleMenu] = useToggle(false)
</script>
