export const groqSettings = () =>
  groq`*[_type == 'siteSettings'][0]{
  ...,
  'siteImage': siteImage.asset->url,
    footerLinks[]->{title, slug, _type},
    navBar[]{
      _type,
      label,
      "location": {
        "nav": navbarLocation,
        "side": sideMenuLocation
      },
      _type == 'menuReference' => {label, dropdowns[]{label, menu->{name, _type, slug}}},
      _type == 'menuUri' => {label, menu}
    },
    serviceMenu[]{label, menu->{title, _type, slug}}
}`
