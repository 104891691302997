<template>
  <MenuBuilder :items="items" :mapping="menuItemMapping" />
</template>

<script setup lang="ts">
import type { Menu, MenuMapping, SiteSettings } from "~/@types/siteSettings"
import { MenuAccordion, MenuUri } from "#components"

const { data: settings } = useNuxtData<SiteSettings>("site-settings")

const items = computed(() => {
  const serviceItem = reactive<Menu>({
    _type: "menuReference",
    label: "Services",
    location: {
      nav: false,
      side: true,
    },
    dropdowns: settings.value?.serviceMenu,
  })

  return [
    ...(settings.value?.navBar?.filter((n) => n.location.side) ?? []),
    ...[serviceItem],
  ]
})

const menuItemMapping: MenuMapping = {
  menuReference: {
    component: MenuAccordion,
  },
  menuUri: {
    component: MenuUri,
    props: {
      class:
        "font-bold uppercase transition-opacity duration-300 hover:opacity-60",
    },
  },
}
</script>
