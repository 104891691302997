<template>
  <NuxtLink
    class="hover:opacity-60"
    :to="permalink(item._type as TypeMap, item.slug)"
    :title="item.name"
  >
    {{ name }}
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { Slug } from "~/@types/schema"
import type { DropdownMenu } from "~/@types/siteSettings"
import type { TypeMap } from "~/@types/utils"
import typeMap from "~/config/typeMap.json"

const props = defineProps<{
  item: DropdownMenu
  label?: string
}>()

const { item, label } = toRefs(props)

const name = computed(() => label.value ?? item.value.name)

const permalink = (type: TypeMap, { current }: Slug) => {
  const parentSlug = typeMap[type]

  return `/${[parentSlug, current].filter(Boolean).join("/")}`
}
</script>
